import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/browser'
import { createUploadLink } from 'apollo-upload-client'

import { sessionEventEmitter } from '../../packs/dashboard/components/SessionChecker/SessionChecker'

const token =
  document.querySelector("[name='csrf-token']")?.attributes.getNamedItem('content')?.value || ''

const authLink = (createUploadLink({
  credentials: 'same-origin',
  headers: {
    'X-CSRF-Token': token,
    authenticity_token: token,
  },
}) as unknown) as ApolloLink // Avoid using apollo-upload-client ApolloLink type definition
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  // Attempt to get the source of the query from the operation context.
  const querySource = operation.getContext().source || 'unknown'

  // Bool to check if the error is due to losing authed status.
  let isMissingAuth = false
  // Put together a nice condensed package of data to log with the error.
  const extraInfo = {
    operation: operation.operationName,
    source: querySource,
    errors:
      graphQLErrors != null
        ? graphQLErrors.map(({ message, locations, path, extensions }) => {
            if (extensions?.code === 'NotLoggedInError') {
              isMissingAuth = true
            }
            return {
              message,
              // Sentry doesn't support deeply nested extra into, so stringify the ones we can.
              locations: JSON.stringify(locations),
              path: JSON.stringify(path),
              extensions: JSON.stringify(extensions),
            }
          })
        : 'no error info',
  }
  // Tags for the error log.
  const tags = {
    layer: 'graphql',
    query: operation.operationName,
  }

  // Do not log errors transpiring due to unauthed-ness.
  if (!isMissingAuth) {
    if (window.CoderPad.ENVIRONMENT === 'development') {
      // In dev env, console log errors.
      if (networkError != null) {
        console.error('GraphQL network error', networkError, tags)
      }
      if (graphQLErrors != null) {
        console.error(`GraphQL error: ${operation?.operationName || 'unknown'}`, extraInfo, tags)
      }
    } else {
      // In staging/production, log the erorrs to Sentry.
      if (networkError != null) {
        Sentry.captureException(networkError, {
          tags,
        })
      }
      if (graphQLErrors != null) {
        Sentry.captureMessage(`GraphQL error: ${operation?.operationName || 'unknown'}`, {
          level: 'error' as Sentry.SeverityLevel,
          tags,
          extra: extraInfo,
        })
      }
    }
  } else {
    sessionEventEmitter.refetchImmediately()
  }
})
const link = ApolloLink.concat(errorLink, authLink)

function buildCacheData(cachePreload: { [key: string]: any }) {
  const restoredData = {
    ROOT_QUERY: {
      __typename: 'Query',
    },
  }
  for (const key in cachePreload) {
    if (cachePreload[key] && cachePreload[key].__typename && cachePreload[key].id) {
      const cacheKey = `${cachePreload[key].__typename}:${cachePreload[key].id}`
      restoredData[cacheKey] = cachePreload[key]
      restoredData.ROOT_QUERY[key] = { __ref: cacheKey }
    } else if (cachePreload[key] === null && key !== 'user') {
      restoredData.ROOT_QUERY[key] = null
    }
  }
  return restoredData
}

const cachePreload = window.CP_GQL_HYDRATE?.data
const cache = new InMemoryCache({
  typePolicies: {
    Question: {
      fields: {
        // testCases, parameters, and draftValidationErrors are arrays of objects on the Question type.
        // They should just be replaced when new values come in, no merging necessary.
        testCases: {
          merge(existing, incoming) {
            return incoming
          },
        },
        parameters: {
          merge(existing, incoming) {
            return incoming
          },
        },
        draftValidationErrors: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
    JoinableOrganizationDomain: {
      // NOTE: Doing this means that, any time a field selection is done on a `JoinableOrganizationDomain`, `domain`
      // and `id` MUST BE IN THE SELECTION. Otherwise Apollo will throw a fit and report an error, despite the query
      // or mutation actually succeeding.
      keyFields: ['id', 'domain'],
    },
  },
})
if (cachePreload) {
  cache.restore(buildCacheData(cachePreload))
}

export const buildApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache,
    link,
  })
}
