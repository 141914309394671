/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie'

/**
 * Get values stored in the cookie
 *
 * @return  {object}  The stored values
 */
export function getStoredValues() {
  const cookie = Cookies.get('__utmzz')
  if (!cookie) {
    return
  }

  const parts = cookie.split('|')
  const dict = {
    utmcsr: 'source',
    utmcmd: 'medium',
    utmccn: 'campaign',
    utmcag: 'obility_group',
    utmcob: 'obility_id',
    utmoff: 'obility_offer',
    utmcct: 'content',
    utmctr: 'keyword',
    utmgclid: 'gclid',
  }
  const output: any = {}
  let part
  let key
  let val
  let i

  for (i = 0; i < parts.length; i++) {
    part = parts[i].split('=')
    key = part[0]
    val = part[1]

    output[dict[key]] = val
  }

  if (output.medium == 'organic') {
    output.campaign = output.source + '_' + output.medium
  }

  return output
}

/**
 * Get local time as ISO string with offset at the end
 *
 * @return  {string}  The local time as an ISO string
 */
export function getLocalTime() {
  const now = new Date()
  const pad = function (num: number) {
    const norm = Math.abs(Math.floor(num))
    return (norm < 10 ? '0' : '') + norm
  }
  return now.getFullYear() + '-' + pad(now.getMonth() + 1) + '-' + pad(now.getDate())
}

/**
 * Parse a query string for utm_* or obility_* parameters
 *
 * @param   {string}  str  Query string to parse
 *
 * @return  {object}       The parsed params
 */
export function parseGoogleParams(str: string) {
  const campaignParams = ['source', 'medium', 'campaign', 'term', 'content']
  const regex = new RegExp(
    '(utm_(' +
      campaignParams.join('|') +
      ')|(obility_offer)|(obility_id)|(obility_group)|(d|g)clid)=.*?([^&#]*|$)',
    'gi'
  )
  const gaParams = str.match(regex)
  let paramsObj: any, vals, len, i

  if (gaParams) {
    paramsObj = {}
    len = gaParams.length

    for (i = 0; i < len; i++) {
      vals = gaParams[i].split('=')
      if (vals) {
        paramsObj[vals[0]] = vals[1]
      }
    }
  }

  return paramsObj
}

/**
 * Parse a referrer URL to determine source and medium values
 *
 * @param   {string}  referrer  URL of the referring page
 *
 * @return  {object}            The parsed referral properties
 */
export function parseGaReferrer(referrer: string) {
  if (!referrer) {
    return
  }

  const searchEngines = {
    'daum.net': {
      p: 'q',
      n: 'daum',
    },
    'eniro.se': {
      p: 'search_word',
      n: 'eniro ',
    },
    'naver.com': {
      p: 'query',
      n: 'naver ',
    },
    'yahoo.com': {
      p: 'p',
      n: 'yahoo',
    },
    'msn.com': {
      p: 'q',
      n: 'msn',
    },
    'bing.com': {
      p: 'q',
      n: 'bing',
    },
    'aol.com': {
      p: 'q',
      n: 'aol',
    },
    'lycos.com': {
      p: 'q',
      n: 'lycos',
    },
    'ask.com': {
      p: 'q',
      n: 'ask',
    },
    'cnn.com': {
      p: 'query',
      n: 'cnn',
    },
    'mamma.com': {
      p: 'query',
      n: 'mama',
    },
    'voila.fr': {
      p: 'rdata',
      n: 'voila',
    },
    'search.virgilio.it': {
      p: 'qs',
      n: 'virgilio',
    },
    'baidu.com': {
      p: 'wd',
      n: 'baidu',
    },
    'yandex.com': {
      p: 'text',
      n: 'yandex',
    },
    'najdi.org.mk': {
      p: 'q',
      n: 'najdi',
    },
    'seznam.cz': {
      p: 'q',
      n: 'seznam',
    },
    'search.com': {
      p: 'q',
      n: 'search',
    },
    'wp.pl': {
      p: 'szukaj ',
      n: 'wirtulana polska',
    },
    'online.onetcenter.org': {
      p: 'qt',
      n: 'o*net',
    },
    'szukacz.pl': {
      p: 'q',
      n: 'szukacz',
    },
    'yam.com': {
      p: 'k',
      n: 'yam',
    },
    'pchome.com': {
      p: 'q',
      n: 'pchome',
    },
    'kvasir.no': {
      p: 'q',
      n: 'kvasir',
    },
    'sesam.no': {
      p: 'q',
      n: 'sesam',
    },
    'ozu.es': {
      p: 'q',
      n: 'ozu ',
    },
    'terra.com': {
      p: 'query',
      n: 'terra',
    },
    'mynet.com': {
      p: 'q',
      n: 'mynet',
    },
    'ekolay.net': {
      p: 'q',
      n: 'ekolay',
    },
    'rambler.ru': {
      p: 'words',
      n: 'rambler',
    },
    'duckduckgo.com': {
      p: 'q',
      n: 'duckduckgo',
    },
    'ecosia.org': {
      p: 'q',
      n: 'ecosia',
    },
    'so.com': {
      p: 'q',
      n: 'so.com',
    },
    'sogou.com': {
      p: 'query',
      n: 'sogou',
    },
    'qwant.com': {
      p: 'q',
      n: 'qwant',
    },
    google: {
      p: 'q',
      n: 'google',
    },
  }
  const thisDomain = getDomain_(document.location.hostname)
  const a = document.createElement('a')
  a.href = referrer

  const values: any = {}
  let referringDomain = getDomain_(referrer)

  // Shim for the billion google search engines
  if (referringDomain.indexOf('google') > -1) {
    referringDomain = 'google'
  }

  if (searchEngines[referringDomain]) {
    const searchEngine = searchEngines[referringDomain]
    const termRegex = new RegExp(searchEngine.p + '=.*?([^&#]*|$)', 'gi')
    const term = a.search.match(termRegex)

    values.source = searchEngine.n
    values.medium = 'organic'

    values.term = (term ? term[0].split('=')[1] : '') || '(not provided)'
  } else if (referringDomain !== thisDomain) {
    values.source = referringDomain
    values.medium = 'referral'
  }

  return values
}

/**
 * Get the domain from a URL
 *
 * @param   {string}  url  The URL to extract the domain of
 *
 * @return  {string}       The domain of the URL
 */
export function getDomain_(url: string) {
  if (!url) {
    return ''
  }

  const a = document.createElement('a')
  a.href = url

  try {
    return a.hostname
  } catch (error) {
    console.error(error)
    return ''
  }
}
