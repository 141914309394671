import * as Sentry from '@sentry/browser'

import { isDevelopment, isMobile, isProduction } from '../environment/environment'

export default function initSentry() {
  if (!isMobile() && !isDevelopment()) {
    Sentry.init({
      dsn: isProduction()
        ? 'https://4d747205c6ed435c889710eaead44e12@sentry.io/10579'
        : 'https://97a6508133a6409786e1da305f3c1b90@o5519.ingest.sentry.io/3804886',
      release: window.CoderPad.RELEASE,
      environment: window.CoderPad.ENVIRONMENT,
    })
  }
  if (window.CoderPad.USER) {
    Sentry.configureScope((scope) => {
      scope.setUser({ email: window.CoderPad.USER.email })
    })
  }
}
