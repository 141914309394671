import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import React, { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ReactComponent as CoderPadLogo } from '../../../../../assets/images/coderpad_logo_monochrome_2024.svg'
import { ReactComponent as JavaLogo } from '../../images/java.svg'
import { ReactComponent as JavascriptLogo } from '../../images/javascript.svg'
import { ReactComponent as Logos } from '../../images/logos.svg'
import { ReactComponent as PythonLogo } from '../../images/python.svg'
import { ReactComponent as ReactLogo } from '../../images/react.svg'

const MOBILE_BREAKPOINT = 1024
const DESKTOP_MEDIA_QUERY = `@media (min-width: ${MOBILE_BREAKPOINT}px)`

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#D91629', // Primary/Red
  minHeight: '100vh',
  [DESKTOP_MEDIA_QUERY]: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
  },
})

const Sidebar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#D91629', // Primary/Red
  padding: theme.spacing(3.5, 0),
  [DESKTOP_MEDIA_QUERY]: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '0 15px 15px 0',
    boxSizing: 'border-box',
    margin: theme.spacing(3.5, 0),
    padding: '60px 40px 20px 40px',
    width: '33vw',
    maxWidth: '480px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '60px 40px 20px 40px',
  },
  '@media (min-width: 1920px)': {
    maxWidth: '540px',
    padding: '60px 60px 20px 60px',
  },
}))

const ContentContainer = styled(Box)({
  backgroundColor: '#FFFFFF',
  flex: 1,
  display: 'flex',
  borderRadius: '15px 15px 0 0',
  overflow: 'hidden',
  [DESKTOP_MEDIA_QUERY]: {
    marginLeft: '33vw',
    padding: '0 40px',
    borderRadius: '0',
  },
})

const Content = styled(Box)(({ theme }) => ({
  width: '100%',
}))

const BrandLogo = styled(CoderPadLogo)(({ theme }) => ({
  cursor: 'pointer',
  width: '160px',
  [theme.breakpoints.up('xl')]: {
    width: '220px',
  },
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
}))

const Title = styled('h3')(({ theme }) => ({
  fontFamily: 'Satoshi, sans-serif',
  color: '#FFFFFF',
  fontSize: '44px',
  fontWeight: 500,
  lineHeight: '57px',
  margin: 0,
  '@media (min-width: 1920px)': {
    fontSize: '60px',
    lineHeight: '66px',
  },
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Satoshi, sans-serif',
  color: '#FFFFFF',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  margin: 0,
  '@media (min-width: 1920px)': {
    fontSize: '24px',
    lineHeight: '36px',
  },
}))

const LanguageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '4%',
  width: 'calc(100% + 40px)',
  zIndex: 1,
  [theme.breakpoints.up('xl')]: {
    width: 'calc(100% + 80px)',
  },
  '@media (max-height: 750px)': {
    display: 'none',
  },
}))

const LanguageTile = styled(Box)({
  backgroundColor: '#FFFFFF',
  color: '#121112', // Primary/Black
  borderRadius: '10%',
  boxShadow:
    '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 4px 21px 4px rgba(0, 0, 0, 0.12), 0px 11px 17px 1px rgba(0, 0, 0, 0.14)',
  padding: '5%',
  aspectRatio: '1/1',
  '@supports not (aspect-ratio: 1/1)': {
    '&::before': {
      float: 'left',
      paddingTop: '100%',
      content: '""',
    },
    '&::after': {
      display: 'block',
      content: '""',
      clear: 'both',
    },
  },
})

const ImageContainer = styled(Box)({
  width: '100%',
  alignSelf: 'center',
})

const languageLogos: React.FC<React.SVGProps<SVGSVGElement>>[] = [
  JavaLogo,
  JavascriptLogo,
  PythonLogo,
  ReactLogo,
]

const CompanyLogos = styled(Logos)({
  opacity: 0.5,
})

interface AuthenticationSidebarProps {
  children: ReactNode
  template?: ReactNode
  title?: string
  subTitle?: string
  image?: ReactNode
}

export const AuthenticationSidebar: React.FC<AuthenticationSidebarProps> = ({
  children,
  template,
  title,
  subTitle,
  image,
}) => {
  const showSidebarContent = useMediaQuery(DESKTOP_MEDIA_QUERY)
  const { pathname } = useLocation()
  const isLoginPage = useMemo(() => pathname.startsWith('/login'), [pathname])

  const displayedTitle =
    title != null
      ? title
      : isLoginPage
      ? "From data science to Django, we've got you covered"
      : 'One step closer to identifying the best candidates'
  const displayedSubTitle =
    subTitle != null
      ? subTitle
      : isLoginPage
      ? 'Feel confident about every hire when you Screen then Interview.'
      : 'Run coding assessments and collaborative technical interviews.'

  const handleLogoClick = () => {
    window.location.href = 'https://coderpad.io/'
  }

  return (
    <Container>
      <Sidebar>
        <BrandLogo onClick={handleLogoClick} />
        {template && showSidebarContent
          ? template
          : showSidebarContent && (
              <>
                <TitleContainer>
                  <Title>{displayedTitle}</Title>
                  <SubTitle>{displayedSubTitle}</SubTitle>
                </TitleContainer>

                {image ? (
                  <ImageContainer>{image}</ImageContainer>
                ) : (
                  <LanguageContainer>
                    {languageLogos.map((Logo, index) => (
                      <LanguageTile key={index}>
                        <Logo height="100%" width="100%" />
                      </LanguageTile>
                    ))}
                  </LanguageContainer>
                )}

                <CompanyLogos />
              </>
            )}
      </Sidebar>

      <ContentContainer>
        <Content>{children}</Content>
      </ContentContainer>
    </Container>
  )
}
