/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie'

import { getDomain_, getLocalTime, parseGaReferrer, parseGoogleParams } from './utmHelpers'

export function updateUtmCookies() {
  const document = window?.document

  if (!document) return

  let gaReferral: any = {
    utmcsr: '(direct)',
    utmcmd: '(none)',
    utmcag: '(not set)',
    utmcob: '(not set)',
    utmoff: '(not set)',
    utmccn: '(not set)',
  }
  const thisDomain = getDomain_(document.location.hostname)
  const referringDomain = getDomain_(document.referrer)
  const sessionCookie = Cookies.get('__utmzzses')
  const qs = document.location.search.replace('?', '')
  const hash = document.location.hash.replace('#', '')
  let gaParams = parseGoogleParams(qs + '#' + hash)
  let referringInfo = parseGaReferrer(document.referrer)
  const storedVals = Cookies.get('__utmz') || Cookies.get('__utmzz')
  const newCookieVals = []
  const keyMap = {
    utm_source: 'utmcsr',
    utm_medium: 'utmcmd',
    utm_campaign: 'utmccn',
    obility_group: 'utmcag',
    obility_id: 'utmcob',
    obility_offer: 'utmoff',
    utm_content: 'utmcct',
    utm_term: 'utmctr',
    gclid: 'utmgclid',
    dclid: 'utmdclid',
  }
  let keyName, values, _val, _key, raw, key, len, i

  if (sessionCookie && referringDomain === thisDomain) {
    gaParams = null
    referringInfo = null
  }

  if (gaParams && (gaParams.utm_source || gaParams.gclid || gaParams.dclid)) {
    for (key in gaParams) {
      if (typeof gaParams[key] !== 'undefined') {
        keyName = keyMap[key]
        gaReferral[keyName] = gaParams[key]
      }
    }

    if (gaParams.gclid || gaParams.dclid) {
      gaReferral.utmcsr = 'google'
      gaReferral.utmcmd = gaReferral.utmgclid ? 'cpc' : 'cpm'
    }
  } else if (referringInfo) {
    gaReferral.utmcsr = referringInfo.source
    gaReferral.utmcmd = referringInfo.medium
    if (referringInfo.term) {
      gaReferral.utmctr = referringInfo.term
    }
  } else if (storedVals) {
    values = {}
    raw = storedVals.split('|')
    len = raw.length
    for (i = 0; i < len; i++) {
      _val = raw[i].split('=')
      _key = _val[0].split('.').pop()
      if (_key) {
        values[_key] = _val[1]
      }
    }
    gaReferral = values
  }

  for (key in gaReferral) {
    if (typeof gaReferral[key] !== 'undefined') {
      newCookieVals.push(key + '=' + gaReferral[key])
    }
  }

  // Figure out referral data
  let theReferrer = document.referrer
  if (referringDomain === thisDomain || referringDomain === 'app.coderpad.io') {
    theReferrer = ''
  }
  if (!Cookies.get('referrer')) {
    // If no referrer cookie has been set then use whatever the document.referrer value is
    theReferrer = document.referrer
  }

  let cookieExpiration = 60 * 60 * 24 * 120 // 120 days
  if (theReferrer) {
    Cookies.set('referrer', theReferrer, { domain: thisDomain, expires: cookieExpiration })
  }
  Cookies.set('__utmzz', newCookieVals.join('|'), { domain: thisDomain, expires: cookieExpiration })
  Cookies.set('__utmzzses', '1', { domain: thisDomain })

  // Set landing page cookies
  const landingPage = window.location.hostname + window.location.pathname
  const date = getLocalTime()
  cookieExpiration = 60 * 60 * 24 * 365 * 10 // 10 years

  if (!Cookies.get('originalLP')) {
    Cookies.set('originalDate', date, { domain: thisDomain, expires: cookieExpiration })
    Cookies.set('originalLP', landingPage, { domain: thisDomain, expires: cookieExpiration })
  }

  if (!Cookies.get('conversionLP')) {
    Cookies.set('conversionDate', date, { domain: thisDomain, expires: cookieExpiration })
    Cookies.set('conversionLP', landingPage, { domain: thisDomain, expires: cookieExpiration })
  }
}
