/**
 * Helper to create a function that wraps the native `fetch` function. This is done so that we can add the auth
 * token to all requests made with function.
 *
 * @param authToken
 */
export function createFetcher(authToken: string) {
  const headers: HeadersInit = {
    'X-CSRF-Token': authToken,
    authenticity_token: authToken,
  }
  function fetcher(url: string, options: RequestInit = {}): Promise<Response> {
    return fetch(url, {
      ...options,
      headers: {
        ...headers,
        ...(options.headers ?? {}),
      },
    })
  }

  // Monkey patch the auth token onto the function so we can use it for hidden inputs.
  fetcher.authToken = authToken

  return fetcher
}
