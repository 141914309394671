import { setup } from '../../packs/main/coderpad_analytics'
import { updateUtmCookies } from '../utm'

export default function initAnalytics() {
  // Do not do this analytics initialization in the pad view. Using presence of `window.padConfig` to indicate that
  // we are in the pad view.
  if (!window.padConfig) {
    const flags = window.CP_GQL_HYDRATE?.data?.user?.flags

    if (flags) {
      setup({
        teamCreationT2p: 'new',
        planUpgradeButton: true,
        planUpgradeFlow: flags['upgrade-downgrade-flow']?.value,
        addToCalendar: flags['add-to-calendar-create-pad-dropdown']?.value,
      })
    } else {
      setup()
    }
  }

  updateUtmCookies()
}
