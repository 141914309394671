import { RemoteStorage } from '@analytics/remote-storage-utils'
import { getItem, setItem } from '@analytics/storage-utils'

const defaultConfig = {
  hubURL: null,
}
let warnedNoHubUrl = false

let syncPromise = false

export default function anonymousId(pluginConfig) {
  return {
    name: 'sync-anonymousId-plugin',
    config: {
      ...defaultConfig,
      ...pluginConfig,
    },
    bootstrap: function (plugin) {
      if (!plugin.config.hubURL && !warnedNoHubUrl) {
        console.warn('No Hub URL: analytics cannot sync the anonymousId across domains')
        warnedNoHubUrl = true
        return
      }
      syncPromise = new Promise(function (resolve, reject) {
        let alreadySynced = getItem('__anon_id_synced')
        if (alreadySynced) {
          return resolve(true)
        }
        const remote = new RemoteStorage(plugin.config.hubURL)
        const remoteGet = remote.getItem('__anon_id')
        remoteGet.then(function (value) {
          let oldAnonId = getItem('__anon_id')
          if (value) {
            setItem('__anon_id', value)
          } else {
            remote.setItem('__anon_id', oldAnonId)
            remote.setItem('__anon_id_synced', true)
          }
          setItem('__anon_id_synced', true)
          resolve(true)
        })
        remoteGet.catch(function () {
          reject(false)
        })
      })
    },
    pageStart: async function (plugin) {
      await syncPromise
    },
    loaded: function () {
      return true
    },
  }
}
