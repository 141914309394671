import { Button, ButtonProps, CircularProgress } from '@mui/material'
import React, { forwardRef, memo } from 'react'

export interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean
}

export const LoadingButton = memo(
  forwardRef<HTMLButtonElement, LoadingButtonProps>(function LoadingButton(props, ref) {
    const { children, isLoading, ...rest } = props

    return (
      <Button
        {...rest}
        ref={ref}
        endIcon={isLoading ? <CircularProgress color="inherit" size={24} /> : null}
      >
        {!isLoading && children}
      </Button>
    )
  })
)

LoadingButton.displayName = 'LoadingButton'
