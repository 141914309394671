export async function loadChatWidget() {
  if (window.hsConversationsSettings.identificationToken == undefined) {
    window.hsConversationsSettings.identificationEmail = window.CoderPad.USER.email
    window.hsConversationsSettings.identificationToken = await getUserToken()
  }

  const loadChat = () => {
    const widgetStatus = window.HubSpotConversations?.widget.status()
    if (widgetStatus?.loaded) {
      window.HubSpotConversations?.widget.open()
    } else {
      window.HubSpotConversations?.widget.load()
    }
  }

  // if hubspot plugin hasn't finished loading, load chat in a callback
  if (window.HubSpotConversations) {
    loadChat()
  } else {
    window.hsConversationsOnReady = [() => loadChat()]
  }
}

export function closeChatWidget() {
  window.HubSpotConversations?.widget.remove()
}

function getUserToken() {
  const user = {
    email: window.CoderPad.USER.email,
    firstName: window.CoderPad.USER.name.split(' ')[0],
    lastName: window.CoderPad.USER.name.split(' ')[1] ?? '',
  }
  return fetch('/hubspot_auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user }),
  })
    .then((response) => response.json())
    .then((data) => data.token)
    .catch(() => {
      console.log('Hubspot - error identifying user')
      return ''
    })
}
