import './appInitializers/googleTranslateHack'

// @ts-ignore no type defs for this package.
import Rails from '@rails/ujs'

import initAnalytics from './appInitializers/analytics'
import initSentry from './appInitializers/sentry'
;(function () {
  initSentry()
  initAnalytics()
})()

Rails.start()
